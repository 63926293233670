import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"



export default (props)=>{
var dispatcher=useDispatch()
var navigate=useNavigate()


    return (<div onClick={()=>{
        dispatcher({type:'set-selectedSchool',payload:props.item})
props.setrevmodal(true)
    }} className="bg-white rounded shadow  border   hover:border-primary pb-2 ">

<img className="w-full border border-primary h-[200px] bg-contain" alt="School logo empty" src={props.item.schoollogo}/>
<p className="font-semibold  text-primary ml-3">{props.item.schoolname}</p>
<p className="font-light text-sm ml-3 text-primary ">{props.item.address}</p>
<p className="font-light text-sm ml-3 text-gray-500 ">{props.item.schooltype}</p>
<p className="font-light  text-sm ml-3 text-primary ">{props.item.creatorrole} : {props.item.user} </p>
 
    </div>)
}

 