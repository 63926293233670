import { useEffect, useState } from "react";
import { logo } from "../../constants/imports";
import { StepFour } from "../../Components/Register/StepFour";
import Button from "../../Components/generic/Button";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import InputField from "../../Components/generic/Inputfield";
import { emailReg } from "../../Utils/regex";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { onLogin } from "../../Utils/Servicess/auth";
import Spinner from "../../Components/generic/Spinner";
import { onGetGovconfig } from "../../Utils/Servicess/user";
 
const Login = () => {
  const [postdata, setpostdata] = useState({});
  const [loading,setLoading]=useState()
const navigate=useNavigate()
const dispather =useDispatch()
  const [currentStepIndexState, setCurrentStepIndex] = useState(0);
  const {control, handleSubmit}=useForm()
  const authuser = useSelector((state) => state.userStore);
  const [config, setconfig] = useState(
    {
        open: false
    });


  const getConfig = async () => {
    setLoading(true);
    var res = await onGetGovconfig();
    setconfig(res.config)
    setLoading(false);
  };

  useEffect(() => {
    getConfig();
  }, []);
  useEffect(() => {
    console.log(postdata);
  }, [postdata]);

  useEffect(() => {
    if (authuser.firstname) {
      navigate("/dashboard");
      return;
    }
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    let formData = {
      email: data.email,
      password: data.password,
    };
    try {
      setLoading(true);
      let res = await onLogin(formData);
    
      if (res.user) {

        if(res.user.position!=undefined){

          toast(res ? res.message : " An error occured");

          dispather({ type: "set-user", payload: res.user });

          navigate("/dashboard");
          localStorage.setItem(`GovstaffUserData`, JSON.stringify(res?.user));


        }


        else{

          toast.error("Invalid Gov. Account (Check URL)")
        }


     
          
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
    setLoading(false);
  };

  return (
    <div className="w-full flex md:p-4 justify-center flex-col  items-center  ">
      <img src={logo} className="m-2  w-[150px] " />
      <Spinner loading={loading} /> 

      <div className="bg-white rounded  flex flex-col p-4 md:px-8 md:w-[30%]">
        <h1 className="mb-3 w-full text-center text-lg ">
          Gov. Staff Login{" "}
        </h1>
        <h3 className="mb-3 w-full text-center font-light text-xs">
          Grade, manage and access schools on the Schoolverse
        </h3>
        <hr className=" m-2" />
        <div className="w-full px-6">
            <InputField
              name="email"
              title="Email Address"
              placeholder="Enter Email Address"
              control={control}
              rules={{
                required: "Email Address is required",
                pattern: {
                  value: emailReg,
                  message: "Invalid Email Address",
                },
              }}
            />

            <InputField
              name="password"
              title="Password"
              placeholder="Enter password"
              control={control}
              rules={{
                required: "Password is required",
              }}
              type={"password"}
            />

            <div className="w-full flex justify-between items-center">
              {" "}
              <p className="text-[13px] text-center text-primary">
                <input type="checkbox" /> Remember me{" "}
              </p>{" "}
              <Link to="/resetpassword">
                <p className=" text-primary text-[13px] cursor-pointer">
                  Forgot password
                </p>
              </Link>
            </div>
            <Button text="Login" onBtnClick={handleSubmit(onSubmit)} />
            { config. open  &&  
              <p className="text-primary text-[13px] w-full text-center">
              New here?{" "}
              <Link className="font-semibold" to="/register">
                Register
              </Link>
            </p>}
          </div>
      </div>
    </div>
  );
};

export { Login };
